// Copyright (C) 2021-2023 Northwestern University.

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";

import { SearchItemCreators } from "/js/invenio_app_rdm/utils";

/**
 * Responsible for last mile adjustments of API return.
 *
 * @param {object} hit Search api hit
 * @returns well constructed object
 */
function deserializeRecord(hit) {
  return {
    access_status: {
      id: hit.ui.access_status.id || "open",
      label: hit.ui.access_status.title_l10n || "Open",
      icon: hit.ui.access_status.icon || "unlock"
    },
    creators: hit.metadata.creators,
    description: hit.ui.description_stripped || "No description",
    id: hit.id,
    link: hit.links.self_html,
    publication_date: hit.ui.publication_date_l10n_long || hit.metadata.publication_date,
    resource_type: hit.metadata.resource_type.title.en,
    title: hit.metadata.title,
    upload_date: hit.ui.created_date_l10n_long,
    version: hit.ui.version
  };
}


function FrontpageRecordCard({item}) {
  return (
    <div className="ui raised card m-0 mobile-mb-1rem prism-record-card">
      <div className="extra content labels-actions">
        <div className="ui primary tiny label">{ item.publication_date } ({ item.version })</div>
        <div className="ui neutral tiny label">{ item.resource_type }</div>
        <div className={ `ui access-status ${item.access_status.id} tiny label` }>
          { item.access_status.icon && (<i className={`icon ${item.access_status.icon}`}/>) }
          { item.access_status.label }
        </div>
      </div>
      <div className="content wrap">
        <h2 className="prism-skinny-header header">
          <a href={ item.link }
             className="link-searchHeaderColor fs-24px webkit-box line-clamp-3">
               { item.title } </a>
        </h2>
        <div className="item creatibutors webkit-box line-clamp-3">
          <SearchItemCreators creators={item.creators} />
        </div>
        <div className="description fs-18px webkit-box line-clamp-5">
          { item.description }
        </div>
      </div>
      <div className="extra right aligned content">
        Uploaded on { item.upload_date }
      </div>
    </div>
  );
}


export function FrontpageRecords(props) {
  const [frontpageRecords, setFrontpageRecords] = useState(
    {hits: [], total: 0}
  );
  const [isLoading, setLoading] = useState(false);

  async function fetchRecords() {
    setLoading(true);

    const response = await axios(
      `/api/records?size=3&q=access.record:public&sort=newest`,
      {
        headers: {
          Accept: "application/vnd.inveniordm.v1+json",
        },
        withCredentials: true,
      }
    );
    let { hits, total } = response.data.hits;
    hits = hits.map(deserializeRecord);

    setLoading(false);
    setFrontpageRecords({ hits, total });
  }

  useEffect(() => {
    fetchRecords();
  }, []);

  const records = frontpageRecords.hits || [];

  if (isLoading) {
    return <Loader active inline="centered" />;
  } else {
    return records.map((item) => (
      <FrontpageRecordCard
        key={item.id}
        item={item}
      />
    ));
  }

};
