// Copyright (C) 2021-2023 Northwstern University.

import React from "react";
import ReactDOM from "react-dom";

import { FrontpageRecords } from "./FrontpageRecords";
import { FrontpageCommunitiesCarousel } from "./FrontpageCommunitiesCarousel";

const frontpageRecordsAppDiv = document.getElementById("frontpageRecords");
const frontpageCommunitiesCarouselAppDiv =
  document.getElementById("frontpageCommunitiesCarousel");


if (frontpageRecordsAppDiv) {
  ReactDOM.render(
    <FrontpageRecords
      qs={JSON.parse(frontpageRecordsAppDiv.dataset.qs)}
    />,
    frontpageRecordsAppDiv
  );
}

if (frontpageCommunitiesCarouselAppDiv) {
  ReactDOM.render(
    <FrontpageCommunitiesCarousel
      defaultLogo="/static/images/square-placeholder.png"
    />,
    frontpageCommunitiesCarouselAppDiv
  );
}
