// Copyright (C) 2021-2023 Northwestern University.

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Transition, Header, Item, Icon, Loader } from "semantic-ui-react";
import _truncate from "lodash/truncate";
import { Image } from "react-invenio-forms";

/*
 * Modulo function since '%' operator in JS is not modulo.
 *
 * We make sure the result is always between 0 and divisor - 1;
*/
function modulo(dividend, divisor) {
  return ((dividend % divisor) + divisor) % divisor;
}


function CarouselItem(props) {
  const { community, defaultLogo, className } = props;

  return (
    <Item
      className={`carousel pa-2rem prism-raised ${className}`}
      key={community.id}
    >

      <div className="ui stackable grid">

        <div className="nine wide column">
          <Header as="h2" className="prism-skinny-header mb-1-5rem" >
            <a href={community.links.self_html} className="link-searchHeaderColor">
              {community.metadata.title}
            </a>
          </Header>
          <p className="fs-24px pl-1em">
            {_truncate(community.metadata.description, {length: 300 })}
          </p>
        </div>

        <div className="column"></div>

        <div className="six wide column centered">
          <Image
            className="prism-raised object-contain h-300px w-auto"
            size="medium"
            src={community.links.logo}
            fallbackSrc={defaultLogo}
            centered
          />
        </div>

      </div>

    </Item>
  );
}


export function FrontpageCommunitiesCarousel(props) {
  const [isLoading, setLoading] = useState(false);
  const [hits, setHits] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState("left");

  // useEffect section
  async function fetchData() {
    setLoading(true);

    try {
      const response = await axios(
        "/api/communities/featured",
        {
          headers: {
            Accept: "application/vnd.inveniordm.v1+json",
          },
          withCredentials: true,
        }
      );

      setLoading(false);
      setHits(response.data.hits.hits);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(
    () => {
      fetchData();
    },
    []
  )

  // render section

  async function left() {
    // Have to await to unbatch state update for transition out direction
    await setAnimationDirection("left");
    setActiveIndex(modulo(activeIndex - 1, hits.length) );
  }

  async function right() {
    // Have to await to unbatch state update for transition out direction
    await setAnimationDirection("right");
    setActiveIndex(modulo(activeIndex + 1, hits.length));
  }

  const { animationSpeed, defaultLogo } = props;

  const carouselSlides = hits
    .filter( (community, index) => index === activeIndex )
    .map(
      (community) =>
        <CarouselItem
          className="frontpage-communities-carousel-slide"
          community={community}
          defaultLogo={defaultLogo}
          key={community.id}
        />
    );

  if (isLoading) {
    return <Loader active inline="centered" />;
  } else {
    return (
      <>
        <Transition.Group
          as={Item.Group}
          className="flex align-items-center"
          duration={animationSpeed}
          animation={`carousel-slide ${animationDirection}`}
          directional
        >
          {carouselSlides}
        </Transition.Group>

        <div className="ui stackable three column centered grid">
          <div className="column">
            <div className="border-t-solid border-t-nmPurpleTint70 border-t-0-4rem pt-0-5rem">
              <Icon
                className="nmPurpleTint70 cursor-pointer hover-nmPurpleTint70Hover"
                role="button"
                name="arrow left"
                size="huge"
                aria-label="Previous slide"
                onClick={left}
                onKeyDown={(event) => event.key === "Enter" && left()}
                tabIndex="0"
              />
              <Icon
                className="nmPurpleTint70 cursor-pointer hover-nmPurpleTint70Hover"
                role="button"
                name="arrow right"
                size="huge"
                aria-label="Next slide"
                onClick={right}
                onKeyDown={(event) => event.key === "Enter" && right()}
                tabIndex="0"
              />
            </div>
          </div>
        </div>
      </>

    );
  }
}
